.App {
  text-align: center;
}


img {
 position: absolute;
 width: 100%;
 height:100%;
 object-fit: none;
}

.image1 {
  margin-left: 33%;
  margin-top: 6%;
  width: 33%;
  height: auto;
  object-fit: contain;
}

.logo {

  position: absolute;
  justify-items: center;
  justify-content: center;
  text-align: center;
  height:  33px;
  width:    100%;
  z-index:1;
  bottom:66px;
  background-color:transparent;
}

svg{
  height:  44px;
  width:    88px;
}

.twitter{
height: 44px;
width: 44px;
position: relative;
 padding-right: 66px;
 object-fit: contain;
}